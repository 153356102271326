/* eslint-disable no-unused-vars */
// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/@core/apiFace/index'

export const getData = createAsyncThunk('appLead/getData', async (params) => {
  const response = await axios.get(`/leads`, {
    params
  })
  return {
    params,
    data: response.data.data,
    pagination: response.data.pagination
  }
})

export const getLead = createAsyncThunk(
  'appLead/getLead',
  async (id) => {
    const response = await axios.get(`/leads/show/${id}`)
    return response.data.data
  }
)

export const addLead = createAsyncThunk(
  'appLead/addLead',
  async ({ data, navigator }, { dispatch, getState }) => {
    // eslint-disable-next-line no-use-before-define
    dispatch(apiCallStart())
    try {
      await axios.post(`leads/store`, data)
      await dispatch(getData(getState().pLead.params))
      navigator('/p/lead/list')
    } catch (e) {
      dispatch(apiCallError(e.response.data.message))
    } finally {
      dispatch(apiCallEnd())
      setTimeout(() => dispatch(apiCallError(null)), 7000)
    }
    return user
  }
)

export const editLead = createAsyncThunk(
  'appLead/editLead',
  async ({ id, data, navigator }, { dispatch, getState }) => {
    try {
      await axios.post(`leads/update/${id}`, data)
      await dispatch(getData(getState().pLead.params))
      navigator('/p/lead/list')
    } catch (e) {
      dispatch(apiCallError(e.response.data.message))
    } finally {
      dispatch(apiCallEnd())
      setTimeout(() => dispatch(apiCallError(null)), 7000)
    }
    return id
  }
)

export const deleteLead = createAsyncThunk(
  'appLead/deleteLead',
  async (id, { dispatch, getState }) => {
    await axios.get(`leads/remove/${id}`)
    await dispatch(getData(getState().pLead.params))
    return id
  }
)

export const resetFormSelected = createAsyncThunk(
  'appLead/resetFormSelected')

export const appLeadSlice = createSlice({
  name: 'appLead',
  initialState: {
    data: [],
    total: 1,
    params: {},
    selected: null,
    api: {
      apiCalls: 0,
      apiError: null
    },
    parentData: {}
  },
  reducers: {
    apiCallStart: (state) => {
      state.api.apiCalls++
    },
    apiCallEnd: (state) => {
      if (state.api.apiCalls > 0) {
        state.api.apiCalls--
      }    },
    apiCallError: (state, action) => {
      state.api.apiError = action.payload || null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.pagination.count
      }).addCase(getLead.fulfilled, (state, action) => {
        state.selected = action.payload
      }).addCase(resetFormSelected.fulfilled, (state, action) => {
        state.selected = null
      })
  }
})

export const { apiCallStart, apiCallEnd, apiCallError } = appLeadSlice.actions

export default appLeadSlice.reducer
