export const countryNames = [{ value: "India", label: "India" }]
export const businessNature = [{ value: "India", label: "India" }]

export const enquiryStages = [{ value: "Open", label: "Open" }, { value: "Started", label: "Started" }, { value: "In-Progress", label: "In-Progress" }, { value: "No Response", label: "No Response" }, { value: "Re-connect", label: "Re-connect" }, { value: "Won", label: "Won" }, { value: "Loss", label: "Loss" }]

export const amcStage = [{ value: "Evaluation", label: "Evaluation" }, { value: "Not Interested", label: "Not Interested" }]

export const projectStages = [{ value: "Open", label: "Open" }, { value: "In-Progress", label: "In-Progress" }, { value: "Done", label: "Done" }, { value: "Loss", label: "Loss" }]

export const milestoneStages = [{ value: "Planning", label: "Planning" }, { value: "Planned", label: "Planned" }, { value: "In-Progress", label: "In-Progress" }, { value: "Done", label: "Done" }, { value: "Under Discussion", label: "Under Discussion" }]

export const taskStatusOptions = [{ value: "Open", label: "Open" }, { value: "In-Progress", label: "In-Progress" }, { value: "Invalid", label: "Invalid" }, { value: "Done", label: "Done" }, { value: "On Hold", label: "On Hold" }]

export const contactTags = [{ value: "Owner", label: "Owner" }, { value: "Accountant", label: "Accountant" }, { value: "Employee", label: "Employee" }, { value: "Admin", label: "Admin" }, { value: "Reseller", label: "Reseller" }]

export const studentTags = [{ value: "Regular", label: "Regular" }, { value: "New", label: "New" }, { value: "Interested", label: "Interested" }, { value: "On Holidays", label: "On Holidays" }, { value: "Old", label: "Old" }]

export const templateTypes = [{ value: "GROUP_AMC", label: "GROUP_AMC" }, { value: "AMC", label: "AMC" }]


export const permissionsDataRaw = [
    {
        title: 'Dashboard',
        items: [
            {
                id: 'alllogs-read',
                title: 'All Logs',
                desc: '',
                defaultValue: true
            },
            {
                id: 'performanceall-read',
                title: 'All Performance Stats',
                desc: '',
                defaultValue: true
            },
            {
                id: 'performanceme-read',
                title: 'Performance Stats Self',
                desc: '',
                defaultValue: true
            },
            {
                id: 'selflogs-read',
                title: 'Own Logs',
                desc: '',
                defaultValue: true
            },
            {
                id: 'pendingamc-read',
                title: 'Dashbord AMC',
                desc: '',
                defaultValue: true
            },
            {
                id: 'dashbordannouncement-read',
                title: 'Dashbord Announcement',
                desc: '',
                defaultValue: true
            },
            {
                id: 'header-read',
                title: 'Sidebar Headers',
                desc: 'This is mandatory*',
                defaultValue: true
            },
            {
                id: 'dashboard-read',
                title: 'Dashboard',
                desc: 'This is mandatory*',
                defaultValue: true
            },
            {
                id: 'dashboardstats-read',
                title: 'Dashboard Stats',
                desc: '',
                defaultValue: true
            },
            {
                id: 'db-add',
                title: 'Database Download',
                desc: '',
                defaultValue: true
            },
            {
                id: 'stu-add',
                title: 'Student Attendance',
                desc: '',
                defaultValue: true
            },
            {
                id: 'tech-add',
                title: 'Teacher Attendance',
                desc: '',
                defaultValue: true
            },
        ]
    },
    {
        title: 'Announcement',
        items: [
            {
                id: 'announcement-add',
                title: 'Announcement Add',
                desc: '',
                defaultValue: true
            },
            {
                id: 'announcement-read',
                title: 'Announcement Read',
                desc: '',
                defaultValue: true
            },
            {
                id: 'announcement-update',
                title: 'Announcement Update',
                desc: '',
                defaultValue: true
            },
            {
                id: 'announcement-detail',
                title: 'Announcement Detail',
                desc: '',
                defaultValue: true
            },
            {
                id: 'announcement-delete',
                title: 'Announcement Delete',
                desc: '',
                defaultValue: true
            },
        ]
    },
    {
        title: 'Users',
        items: [
            {
                id: 'users-add',
                title: 'Users Add',
                desc: '',
                defaultValue: true
            },
            {
                id: 'users-read',
                title: 'Users Read',
                desc: '',
                defaultValue: true
            },
            {
                id: 'users-update',
                title: 'Users Update',
                desc: '',
                defaultValue: true
            },
            {
                id: 'users-detail',
                title: 'Users Detail',
                desc: '',
                defaultValue: true
            },
            {
                id: 'users-delete',
                title: 'Users Delete',
                desc: '',
                defaultValue: true
            },
        ]
    },
    {
        title: 'Roles & Permissions',
        items: [
            {
                id: 'roles-read',
                title: 'Roles & Permissions Read',
                desc: '',
                defaultValue: true
            },
            {
                id: 'roles-update',
                title: 'Roles & Permissions Update',
                desc: '',
                defaultValue: true
            }
        ]
    },
    // new role
    {
        title: 'Students',
        items: [
            {
                id: 'student-add',
                title: 'Student Add',
                desc: '',
                defaultValue: true
            },
            {
                id: 'student-read',
                title: 'Student Read',
                desc: '',
                defaultValue: true
            },
            {
                id: 'student-update',
                title: 'Student Update',
                desc: '',
                defaultValue: true
            },
            {
                id: 'student-detail',
                title: 'Student Detail',
                desc: '',
                defaultValue: true
            },
            {
                id: 'student-delete',
                title: 'Student Delete',
                desc: '',
                defaultValue: true
            },
        ]
    },

    {
        title: 'Location',
        items: [
            {
                id: 'location-add',
                title: 'Location Add',
                desc: '',
                defaultValue: true
            },
            {
                id: 'location-read',
                title: 'Location Read',
                desc: '',
                defaultValue: true
            },
            {
                id: 'location-update',
                title: 'Location Update',
                desc: '',
                defaultValue: true
            },
            {
                id: 'location-detail',
                title: 'Location Detail',
                desc: '',
                defaultValue: true
            },
            {
                id: 'location-delete',
                title: 'Location Delete',
                desc: '',
                defaultValue: true
            },
        ]
    },

    {
        title: 'Classes',
        items: [
            {
                id: 'class-add',
                title: 'Class Add',
                desc: '',
                defaultValue: true
            },
            {
                id: 'class-read',
                title: 'Class Read',
                desc: '',
                defaultValue: true
            },
            {
                id: 'class-update',
                title: 'Class Update',
                desc: '',
                defaultValue: true
            },
            {
                id: 'class-detail',
                title: 'Class Detail',
                desc: '',
                defaultValue: true
            },
            {
                id: 'class-delete',
                title: 'Class Delete',
                desc: '',
                defaultValue: true
            },
        ]
    },
]