// ** Icons Import
import { Home, Circle, Box } from 'react-feather'

export default [
  {
    id: 'dashboards',
    title: 'Dashboards',
    icon: <Home size={20} />,
    badge: 'light-warning',
    children: [
      {
        id: 'home',
        title: 'Home',
        icon: <Box size={12} />,
        navLink: '/dashboard/home',
        action: 'read',
        resource: 'dashboard',
      }
      // {
      //   id: 'eCommerceDash',
      //   title: 'eCommerce',
      //   icon: <Circle size={12} />,
      //   navLink: '/dashboard/ecommerce'
      // }
    ]
  }
]
