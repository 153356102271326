// ** Navigation imports
import apps from './apps'
import pages from './pages'
import forms from './forms'
import tables from './tables'
import others from './others'
import charts from './charts'
import dashboards from './dashboards'
import uiElements from './ui-elements'


import _all from './_all'

// ** Merge & Export
// ,...apps, ...pages, ...uiElements, ...forms, ...tables, ...charts, ...others || 
let urls = [...dashboards, ..._all,]

if (process.env.REACT_APP_ENV == 'dev') {
    urls = [...urls, ...apps, ...pages, ...uiElements, ...forms, ...tables, ...charts, ...others]
}

export default urls