/* eslint-disable no-unused-vars */
// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/@core/apiFace/index'


export const getData = createAsyncThunk('appMilestone/getData', async (params) => {
  const response = await axios.get(`/milestones`, {
    params
  })
  return {
    params,
    data: response.data.data,
    pagination: response.data.pagination
  }
})

export const getMilestone = createAsyncThunk(
  'appMilestone/getMilestone',
  async (id) => {
    const response = await axios.get(`/milestones/show/${id}`)
    return response.data.data
  }
)


export const updateRunningMilestone = createAsyncThunk(
  'appMilestone/updateRunningMilestone',
  async ({ id }, { dispatch, getState }) => {
    const response = await axios.post(`/milestones/${id}/updaterunning`)
    await dispatch(getData(getState().pMilestone.params))
    return response.data.data
  }
)

export const addCommentMilestore = createAsyncThunk(
  'appMilestone/addCommentMilestore',
  async ({ id, data, handler }, { dispatch, getState }) => {
    let response
    dispatch(apiCallStart())
    try {
      response = await axios.post(`/milestones/${id}/addcomment`, data)
      await dispatch(getData(getState().pMilestone.params))
      handler()
    } catch (e) {
      dispatch(apiCallError(e.response.data.message))
    } finally {
      dispatch(apiCallEnd())
      setTimeout(() => dispatch(apiCallError(null)), 7000)
    }
    return response ? response.data : null
  }
)

export const resetFormSelected = createAsyncThunk(
  'appSub/resetFormSelected')


export const addMilestone = createAsyncThunk(
  'appMilestone/addMilestone',
  async ({ data, handler }, { dispatch, getState }) => {
    dispatch(apiCallStart())
    try {
      await axios.post(`milestones/store`, data)
      await dispatch(getData(getState().pMilestone.params))
      handler()
    } catch (e) {
      dispatch(apiCallError(e.response.data.message))
    } finally {
      dispatch(apiCallEnd())
      setTimeout(() => dispatch(apiCallError(null)), 7000)
    }
    return user
  }
)

export const editMilestone = createAsyncThunk(
  'appMilestone/editMilestone',
  async ({ id, data, handler }, { dispatch, getState }) => {
    try {
      await axios.post(`milestones/update/${id}`, data)
      handler()
      await dispatch(getData(getState().pMilestone.params))
    } catch (e) {
      dispatch(apiCallError(e.response.data.message))
    } finally {
      dispatch(apiCallEnd())
      setTimeout(() => dispatch(apiCallError(null)), 7000)
    }
    return id
  }
)

export const deleteMilestone = createAsyncThunk(
  'appMilestone/deleteMilestone',
  async (id, { dispatch, getState }) => {
    await axios.get(`milestones/remove/${id}`)
    await dispatch(getData(getState().pMilestone.params))
    return id
  }
)

export const appMilestoneSlice = createSlice({
  name: 'appMilestone',
  initialState: {
    data: [],
    total: 1,
    params: {},
    selected: null,
    api: {
      apiCalls: 0,
      apiError: null
    },
    parentData: {}
  },
  reducers: {
    apiCallStart: (state) => {
      state.api.apiCalls++
    },
    apiCallEnd: (state) => {
      if (state.api.apiCalls > 0) {
        state.api.apiCalls--
      }
    },
    apiCallError: (state, action) => {
      state.api.apiError = action.payload || null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.pagination.count
      }).addCase(getMilestone.fulfilled, (state, action) => {
        state.selected = action.payload
      }).addCase(resetFormSelected.fulfilled, (state, action) => {
        state.selected = null
      })
  }
})

export const { apiCallStart, apiCallEnd, apiCallError } = appMilestoneSlice.actions

export default appMilestoneSlice.reducer
