/* eslint-disable no-unused-vars */
// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/@core/apiFace/index'

export const getData = createAsyncThunk('appTemplate/getData', async (params) => {
  const response = await axios.get(`/templates`)
  return {
    data: response.data.data,
  }
})

export const getTemplate = createAsyncThunk(
  'appTemplate/getTemplate',
  async (id) => {
    const response = await axios.get(`/templates/show/${id}`)
    return response.data.data
  }
)

export const addTemplate = createAsyncThunk(
  'appTemplate/addTemplate',
  async ({ data, navigator, handleReset }, { dispatch, getState }) => {
    dispatch(apiCallStart())
    try {
      await axios.post(`templates/store`, data)
      await dispatch(getData(getState().pTemplate.params))
      handleReset()
      navigator('/p/template/list')
    } catch (e) {
      dispatch(apiCallError(e.response.data.message))
    } finally {
      dispatch(apiCallEnd())
    }
    return user
  }
)

export const editTemplate = createAsyncThunk(
  'appTemplate/editTemplate',
  async ({ id, data, navigator }, { dispatch, getState }) => {
    try {
      await axios.post(`templates/update/${id}`, data)
      await dispatch(getData(getState().pTemplate.params))
      navigator('/p/template/list')
    } catch (e) {
      dispatch(apiCallError(e.response.data.message))
    } finally {
      dispatch(apiCallEnd())
      setTimeout(() => dispatch(apiCallError(null)), 7000)
    }
    return id
  }
)

export const deleteTemplate = createAsyncThunk(
  'appTemplate/deleteTemplate',
  async (id, { dispatch, getState }) => {
    await axios.get(`templates/remove/${id}`)
    await dispatch(getData(getState().pTemplate.params))
    return id
  }
)

export const sendNotification = createAsyncThunk(
  'appTemplate/sendNotification',
  async ({ data, resetAll }, { dispatch, getState }) => {
    dispatch(apiCallStart())
    try {
      let response = await axios.post(`sendNotification`, data)
      resetAll()
      return response
    } catch (e) {
      dispatch(apiCallError(e.response.data.message))
    } finally {
      dispatch(apiCallEnd())
    }
  }
)

export const appTemplateSlice = createSlice({
  name: 'appTemplate',
  initialState: {
    data: [],
    total: 1,
    whatsappModel: false,
    whatsappModelData: null,
    selected: null,
    api: {
      apiCalls: 0,
      apiError: null
    },
    parentData: {}
  },
  reducers: {
    apiCallStart: (state) => {
      state.api.apiCalls++
    },
    apiCallEnd: (state) => {
      if (state.api.apiCalls > 0) {
        state.api.apiCalls--
      }
    },
    apiCallError: (state, action) => {
      state.api.apiError = action.payload || null
    },
    whatsappModelAction: (state) => {
      state.whatsappModel = !state.whatsappModel
    },
    whatsappModelData: (state, action) => {
      state.whatsappModelData = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
      }).addCase(getTemplate.fulfilled, (state, action) => {
        state.selected = action.payload
      }).addCase(sendNotification.fulfilled, (state, action) => {
        // state.selected = action.payload
        console.log(action)
      })
  }
})

export const { apiCallStart, apiCallEnd, apiCallError, whatsappModelAction, whatsappModelData } = appTemplateSlice.actions

export default appTemplateSlice.reducer
