/* eslint-disable no-unused-vars */
// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/@core/apiFace/index'

export const getData = createAsyncThunk('appSub/getData', async (params) => {
  const response = await axios.get(`/amctransactions`, {
    params
  })
  return {
    params,
    data: response.data.data,
    pagination: response.data.pagination
  }
})

export const getSub = createAsyncThunk(
  'appSub/getSub',
  async (id) => {
    const response = await axios.get(`/amctransactions/show/${id}`)
    return response.data.data
  }
)

export const addSub = createAsyncThunk(
  'appSub/addSub',
  async ({ data, navigator }, { dispatch, getState }) => {
    dispatch(apiCallStart())
    try {
      await axios.post(`amctransactions/store`, data)
      await dispatch(getData(getState().pSub.params))
      navigator('/p/amc/list')
    } catch (e) {
      dispatch(apiCallError(e.response.data.message))
    } finally {
      dispatch(apiCallEnd())
      setTimeout(() => dispatch(apiCallError(null)), 7000)
    }
    return data
  }
)

export const addRenew = createAsyncThunk(
  'appSub/addRenew',
  async ({ data, handleReset }, { dispatch, getState }) => {
    dispatch(apiCallStart())
    try {
      await axios.post(`amctransactions/renew`, data)
      await dispatch(getData(getState().pSub.params))
      // navigator('/p/amc/list')
      handleReset();
    } catch (e) {
      dispatch(apiCallError(e.response.data.message))
    } finally {
      dispatch(apiCallEnd())
      setTimeout(() => dispatch(apiCallError(null)), 7000)
    }
    return data
  }
)

export const addMore = createAsyncThunk(
  'appSub/addMore',
  async ({ data, handleReset }, { dispatch, getState }) => {
    dispatch(apiCallStart())
    try {
      await axios.post(`amctransactions/addmore`, data)
      await dispatch(getData(getState().pSub.params))
      // navigator('/p/amc/list')
      handleReset();
    } catch (e) {
      dispatch(apiCallError(e.response.data.message))
    } finally {
      dispatch(apiCallEnd())
      setTimeout(() => dispatch(apiCallError(null)), 7000)
    }
    return data
  }
)

export const editSub = createAsyncThunk(
  'appSub/editSub',
  async ({ id, data, navigator }, { dispatch, getState }) => {
    try {
      await axios.post(`amctransactions/update/${id}`, data)
      await dispatch(getData(getState().pSub.params))
      navigator('/p/amc/list')
    } catch (e) {
      dispatch(apiCallError(e.response.data.message))
    } finally {
      dispatch(apiCallEnd())
      setTimeout(() => dispatch(apiCallError(null)), 7000)
    }
    return id
  }
)

export const deleteSub = createAsyncThunk(
  'appSub/deleteSub',
  async (id, { dispatch, getState }) => {
    await axios.get(`amctransactions/remove/${id}`)
    await dispatch(getData(getState().pSub.params))
    return id
  }
)

export const closeSub = createAsyncThunk(
  'appSub/closeSub',
  async (id, { dispatch, getState }) => {
    await axios.post(`amctransactions/${id}/close`)
    await dispatch(getData(getState().pSub.params))
    return id
  }
)

export const resetFormSelected = createAsyncThunk(
  'appSub/resetFormSelected')

export const appSubSlice = createSlice({
  name: 'appSub',
  initialState: {
    data: [],
    total: 1,
    params: {},
    selected: null,
    api: {
      apiCalls: 0,
      apiError: null
    },
    parentData: {}
  },
  reducers: {
    apiCallStart: (state) => {
      state.api.apiCalls++
    },
    apiCallEnd: (state) => {
      if (state.api.apiCalls > 0) {
        state.api.apiCalls--
      }
    },
    apiCallError: (state, action) => {
      state.api.apiError = action.payload || null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.pagination.count
      }).addCase(getSub.fulfilled, (state, action) => {
        state.selected = action.payload
      }).addCase(resetFormSelected.fulfilled, (state, action) => {
        state.selected = null
      })
  }
})

export const { apiCallStart, apiCallEnd, apiCallError } = appSubSlice.actions

export default appSubSlice.reducer
