/* eslint-disable no-unused-vars */
// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/@core/apiFace/index'

export const getParentCompany = createAsyncThunk(
  'appCompany/getParentCompany',
  async () => {
    const response = await axios.get(
      `/companies?orderAsc=1&orderBy=name&q=&page=1&limit=99999`
    )
    return response.data.data.rows
  }
)

export const getData = createAsyncThunk('appCompany/getData', async (params) => {
  const response = await axios.get(`/companies`, {
    params
  })
  return {
    params,
    data: response.data.data,
    pagination: response.data.pagination
  }
})

export const getCompany = createAsyncThunk(
  'appCompany/getCompany',
  async (id) => {
    const response = await axios.get(`/companies/show/${id}`)
    return response.data.data
  }
)

export const addCompany = createAsyncThunk(
  'appCompany/addCompany',
  async ({ data, navigator }, { dispatch, getState }) => {
    dispatch(apiCallStart())
    try {
      await axios.post(`companies/store`, data)
      await dispatch(getData(getState().pCompany.params))
      navigator('/p/company/list')
    } catch (e) {
      dispatch(apiCallError(e.response.data.message))
    } finally {
      dispatch(apiCallEnd())
      setTimeout(() => dispatch(apiCallError(null)), 7000)
    }
    return user
  }
)

export const editCompany = createAsyncThunk(
  'appCompany/editCompany',
  async ({ id, data, navigator }, { dispatch, getState }) => {
    try {
      await axios.post(`companies/update/${id}`, data)
      await dispatch(getData(getState().pCompany.params))
      navigator('/p/company/list')
    } catch (e) {
      dispatch(apiCallError(e.response.data.message))
    } finally {
      dispatch(apiCallEnd())
      setTimeout(() => dispatch(apiCallError(null)), 7000)
    }
    return id
  }
)

export const deleteCompany = createAsyncThunk(
  'appCompany/deleteCompany',
  async (id, { dispatch, getState }) => {
    await axios.get(`companies/remove/${id}`)
    await dispatch(getData(getState().pCompany.params))
    return id
  }
)

export const appCompanySlice = createSlice({
  name: 'appCompany',
  initialState: {
    data: [],
    total: 1,
    params: {},
    selected: null,
    api: {
      apiCalls: 0,
      apiError: null
    },
    parentData: {}
  },
  reducers: {
    apiCallStart: (state) => {
      state.api.apiCalls++
    },
    apiCallEnd: (state) => {
      if (state.api.apiCalls > 0) {
        state.api.apiCalls--
      }
    },
    apiCallError: (state, action) => {
      state.api.apiError = action.payload || null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.pagination.count
      }).addCase(getCompany.fulfilled, (state, action) => {
        state.selected = action.payload
      })
  }
})

export const { apiCallStart, apiCallEnd, apiCallError } = appCompanySlice.actions

export default appCompanySlice.reducer
