import { DefaultRoute } from '../router/routes'
import moment from 'moment'
import * as XLSX from 'xlsx'

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => obj != undefined && obj != null && Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = (num) => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: 'short', day: 'numeric', year: 'numeric' }
) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))
export const getDefaultLocation = () => localStorage.getItem('defaultLocation')
export const getDefaultLocationIfAll = () => {
  let val = localStorage.getItem('defaultLocation')
  if (val=='all') {
    return getUserData()['allowedLocations'][0]    
  } else {
    return val
  }
}

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === 'admin') return DefaultRoute
  if (userRole === 'client') return '/access-control'
  return DefaultRoute
}

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

/* eslint-disable implicit-arrow-linebreak */
export const API_ENDPOINT = () =>
  process.env.REACT_APP_API_ENDPOINT || 'http://localhost:8080/api'
export const MEDIA_ENDPOINT = () =>
  process.env.REACT_APP_MEDIA_ENDPOINT || 'http://localhost:8080/bstatic'

export const disableConsoleProd = () => {
  if (process.env.REACT_APP_ENV != 'dev') console.log = function () { }
}

export const MEDIA_IMAGE_SIZE = (link, size) => {
  const linkPart = link.split('/')
  linkPart[1] = size
  return MEDIA_ENDPOINT() + linkPart.join('/')
}

export const MEDIA_FILE_NAME = (link) => {
  const linkPart = link.split('/')
  return linkPart[linkPart.length - 1]
}

export const MEDIA_LINK = (link) => {
  const linkPart = link.split('/')
  linkPart[1] = 'original'
  return MEDIA_ENDPOINT() + linkPart.join('/')
}

export const MEDIA_NAME = (link) => {
  const linkPart = link.split('/')
  return linkPart[linkPart.length - 1]
}

export const toBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export const toSelectOprtions = (items, valueKey, labelKey) => {
  return items.map((e) => {
    return { value: e[valueKey], label: e[labelKey] }
  })
}

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

export function textContent(elem) {
  if (!elem) {
    return ''
  }
  if (typeof elem === 'string') {
    return elem
  }
  const children = elem.props && elem.props.children
  if (children instanceof Array) {
    return children.map(textContent).join('')
  }
  return textContent(children)
}

export const convertArrayOfObjectsToCSV = function (array) {
  let result

  const columnDelimiter = ';'
  const lineDelimiter = '\n'
  const keys = Object.keys(array[0])

  result = ''
  result += keys.join(columnDelimiter)
  result += lineDelimiter

  array.forEach((item) => {
    let ctr = 0
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter
      result += item[key]
      ctr++
    })
    result += lineDelimiter
  })

  return result
}

export const formatedArray = function (array, masterStore, selected = []) {
  let newAr = []
  for (let rowx of array) {
    let row = { ...rowx }
    let keys = Object.keys(row)
    for (let k of keys) {
      if (!selected.includes(k)) {
        continue;
      }
      let f = null
      switch (k) {
        case 'companyWith':
          if (typeof row[k] == 'string' || row[k] == null)
            f = masterStore.companyWith.find((e) => e.value == row[k])
          else {
            f = row[k].map(x => masterStore.companyWith.find((e) => e.value == x)).map(e => textContent(e.label))
            f = { label: f.join(', ') }
          }
          if (f) {
            row[k] = textContent(f.label)
          }
          break
        case 'templateSingle':
          f = masterStore.template.find((e) => e.value == row[k])
          if (f) {
            row[k] = textContent(f.label)
          }
          break
        case 'templateGroup':
          f = masterStore.template.find((e) => e.value == row[k])
          if (f) {
            row[k] = textContent(f.label)
          }
          break
        case 'products':
          if (typeof row[k] == 'string' || row[k] == null)
            f = masterStore.products.find((e) => e.value == row[k])
          else {
            f = row[k].map(x => masterStore.products.find((e) => e.value == x)).map(e => textContent(e.label))
            f = { label: f.join(', ') }
          }
          if (f) {
            row[k] = textContent(f.label)
          }
          break
        case 'assignedTo':
          if (typeof row[k] == 'string' || row[k] == null)
            f = masterStore.assignedTo.find((e) => e.value == row[k])
          else {
            f = row[k].map(x => masterStore.assignedTo.find((e) => e.value == x)).map(e => textContent(e.label))
            f = { label: f.join(', ') }
          }
          if (f) {
            row[k] = textContent(f.label)
          }
          break
        case 'refferedBy':
          if (typeof row[k] == 'string' || row[k] == null)
            f = masterStore.refferedBy.find((e) => e.value == row[k])
          else {
            f = row[k].map(x => masterStore.refferedBy.find((e) => e.value == x)).map(e => textContent(e.label))
            f = { label: f.join(', ') }
          }
          if (f) {
            row[k] = textContent(f.label)
          }
          break
        default:
          if (typeof row[k] == 'string' || typeof row[k] == 'number') {
            row[k] = row[k]
          } else {
            row[k] = JSON.stringify(row[k])
          }
      }
      let newOrder = {}
      for (let s of selected) {
        newOrder[s] = row[s] || ''
      }
      row = newOrder
    }
    newAr.push(row)
  }
  return newAr
}

export const downloadCSV = function (array, masterState, selected = []) {
  if (array.length == 0) {
    alert('No for for export')
    return
  }
  if (selected.length == 0) {
    selected = Object.keys(array[0])
  }
  let newArray = formatedArray(array, masterState, selected)
  let dateTime = +new Date()
  const filename = dateTime + '-export.xlsx'
  const worksheet = XLSX.utils.json_to_sheet(newArray)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
  XLSX.writeFile(workbook, filename)
}

export const makeid = function (length = 10) {
  let result = ''
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz01234567892eYGXEQR9rHo7VPlyILbJs3uOjkWBNK5AFDgfq10pm86aiZwSTtC4vzcUxMnhd'
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}

export const generateRandomInt = () => {
  return Math.round(Math.random() * 1000000, 0)
}
export const generateRandomChar = () => {
  return makeid(10)
}

export const showDateTime = (val, showTime = false) => {
  if (showTime) return moment(val).format('D MMM, YYYY hh:mm A')
  return moment(val).format('D MMM, YYYY')
}

export const invoiceNo = () => {
  return makeid(7) + '-' + moment().format('DMMYYhhmmss')
}

export const addToClipboard = (document, cb = (_) => { }, isTest = false) => {
  var myBtn = document.querySelectorAll('.copy-txt')
  const copyContent = async (e) => {
    let text = e.target.textContent || ''
    try {
      await navigator.clipboard.writeText(text)
      cb()
    } catch (err) {
      console.error('Failed to copy: ', err)
    }
  }
  for (var i = 0; i < myBtn.length; i++) {
    myBtn[i].addEventListener('click', copyContent, false)
    if (isTest) myBtn[i].style.backgroundColor = 'salmon'
  }
}

export const selectToValue = (data, key, defaultReturn = null) => {
  return typeof data[key] != 'string'
    ? data[key]
      ? data[key].value
      : defaultReturn
    : data[key] || defaultReturn
}

export const remainingDays = (st = null, ed) => {
  let edD = moment(ed)
  let diff = edD.diff(moment(), 'days')
  if (diff > 0) {
    return diff
  }
  return 0
}

export const remainingDaysNew = (ed) => {
  let edD = moment(ed)
  return edD.diff(moment(), 'days')
}

export const remainingDaysSt = (st, ed) => {
  let stD = moment(st)
  let edD = moment(ed)
  let diff = edD.diff(stD, 'days')
  if (diff > 0) {
    return diff
  }
  return 0
}

export const randomColorGenerator = () => {
  let c = `#${Math.floor(Math.random() * 16777215).toString(16)}`
  while (c.length != 7) {
    c = randomColorGenerator()
  }
  return c
}

export const toSlug = (st) => {
  return st
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-')
}