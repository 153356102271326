import { lazy } from 'react'
import { BsCardChecklist } from 'react-icons/bs'

// const UsersPage = lazy(() => import('../../views/store/user/list'))
// const CategoryPage = lazy(() => import('../../views/store/category/list'))
// const CategoryFormUpdate = lazy(() => import('../../views/store/category/view/CategoryFormUpdate'))

const CompanyPage = lazy(() => import('../../views/store/company/list'))
const CompanyEditPage = lazy(() => import('../../views/store/company/view/FormUpdate.js'))
const CompanyDetailPage = lazy(() => import('../../views/store/company/details/index.js'))

const ContactPage = lazy(() => import('../../views/store/contact/list'))
const ContactEditPage = lazy(() => import('../../views/store/contact/view/FormUpdate.js'))
const ContactDetailPage = lazy(() => import('../../views/store/contact/details/index.js'))

const ProductPage = lazy(() => import('../../views/store/product/list'))
const ProductFormPage = lazy(() => import('../../views/store/product/view/FormUpdate.js'))
const ProductDetailPage = lazy(() => import('../../views/store/product/details/index.js'))

const EnquiryPage = lazy(() => import('../../views/store/enquiry/list'))
const EnquiryFormPage = lazy(() => import('../../views/store/enquiry/view/FormUpdate.js'))
const EnquiryDetailPage = lazy(() => import('../../views/store/enquiry/details/index.js'))


const LeadPage = lazy(() => import('../../views/store/lead/list'))
const LeadFormPage = lazy(() => import('../../views/store/lead/view/FormUpdate.js'))
const LeadDetailPage = lazy(() => import('../../views/store/lead/details/index.js'))

const AmcPage = lazy(() => import('../../views/store/amc/list'))
const AmcFormPage = lazy(() => import('../../views/store/amc/view/FormUpdate.js'))
const AmcDetailPage = lazy(() => import('../../views/store/amc/details/index.js'))

const AmcTPage = lazy(() => import('../../views/store/amctransactions/list'))
const AmcTFormPage = lazy(() => import('../../views/store/amctransactions/view/FormUpdate.js'))

const AnnouncementTPage = lazy(() => import('../../views/store/announcement/list'))
const AnnouncementFormPage = lazy(() => import('../../views/store/announcement/view/FormUpdate.js'))

const TemplatePage = lazy(() => import('../../views/store/template/list'))
const TemplateFormPage = lazy(() => import('../../views/store/template/view/FormUpdate.js'))

const User = lazy(() => import('../../views/store/users/list'))
const UserFormPage = lazy(() => import('../../views/store/users/view/FormUpdate.js'))
const UserDetailPage = lazy(() => import('../../views/store/users/details/index.js'))
const Profile = lazy(() => import('../../views/store/users/profile/index.js'))
const Permission = lazy(() => import('../../views/store/permission/index.js'))

const Project = lazy(() => import('../../views/store/project/list'))
const ProjectEditPage = lazy(() => import('../../views/store/project/view/FormUpdate.js'))
const ProjectDetailPage = lazy(() => import('../../views/store/project/details/index.js'))

const Milestone = lazy(() => import('../../views/store/milestone/list'))
const DbDownload = lazy(()=> import('../../views/store/db-download'))


const Todo = lazy(() => import('../../views/store/todo'))

 // new 

const StudentPage = lazy(() => import('../../views/store/student/list'))
const StudentEditPage = lazy(() => import('../../views/store/student/view/FormUpdate.js'))
const StudentDetailPage = lazy(() => import('../../views/store/student/details/index.js'))

const LocationPage = lazy(() => import('../../views/store/location/list'))
const LocationEditPage = lazy(() => import('../../views/store/location/view/FormUpdate.js'))
const LocationDetailPage = lazy(() => import('../../views/store/location/details/index.js'))

const ClassPage = lazy(() => import('../../views/store/class/list'))
const ClassEditPage = lazy(() => import('../../views/store/class/view/FormUpdate.js'))
const ClassDetailPage = lazy(() => import('../../views/store/class/details/index.js'))

const Attendance = lazy(()=> import('../../views/store/attendance'))
const TechAttendance = lazy(()=> import('../../views/store/techattendance'))

const DashboardRoutes = [
  // Contacts/Vendor Routes
  {
    path: '/p/users/list',
    element: <User />,
    meta: {
      action: 'read',
      resource: 'users'
    }
  },
  {
    path: '/p/users/add',
    element: <UserFormPage />,
    meta: {
      action: 'add',
      resource: 'users'
    }
  },
  {
    path: '/p/users/:id/edit',
    element: <UserFormPage />,
    meta: {
      action: 'update',
      resource: 'users'
    }
  },
  {
    path: '/p/users/roles',
    element: <Permission />,
    meta: {
      action: 'read',
      resource: 'roles'
    }
  },
  {
    path: '/p/users/view/:id',
    element: <UserDetailPage />,
    meta: {
      action: 'detail',
      resource: 'users'
    }
  },
  {
    path: '/p/users/profile',
    element: <Profile />
  },
  
  // announcement
  {
    path: '/p/announcement/list',
    element: <AnnouncementTPage />,
    meta: {
      action: 'read',
      resource: 'announcement'
    }
  },
  {
    path: '/p/announcement/add',
    element: <AnnouncementFormPage />,
    meta: {
      action: 'add',
      resource: 'announcement'
    }
  },
  {
    path: '/p/announcement/:id/edit',
    element: <AnnouncementFormPage />,
    meta: {
      action: 'update',
      resource: 'announcement'
    }
  },
  {
    element: <DbDownload />,
    path: '/p/db',
    meta: {
      appLayout: true,
      className: 'db-add',
      action: 'add',
      resource: 'db'
    }
  },
  // new 
  {
    path: '/p/student/list',
    element: <StudentPage />,
    meta: {
      action: 'read',
      resource: 'student'
    }
  },
  {
    path: '/p/student/add',
    element: <StudentEditPage />,
    meta: {
      action: 'add',
      resource: 'student'
    }
  },
  {
    path: '/p/student/:id/edit',
    element: <StudentEditPage />,
    meta: {
      action: 'update',
      resource: 'student'
    }
  },
  {
    path: '/p/student/view/:id',
    element: <StudentDetailPage />,
    meta: {
      action: 'detail',
      resource: 'student'
    }
  },
  {
    path: '/p/location/list',
    element: <LocationPage />,
    meta: {
      action: 'read',
      resource: 'location'
    }
  },
  {
    path: '/p/location/add',
    element: <LocationEditPage />,
    meta: {
      action: 'add',
      resource: 'location'
    }
  },
  {
    path: '/p/location/:id/edit',
    element: <LocationEditPage />,
    meta: {
      action: 'update',
      resource: 'location'
    }
  },
  {
    path: '/p/location/view/:id',
    element: <LocationDetailPage />,
    meta: {
      action: 'detail',
      resource: 'location'
    }
  },
  
  {
    path: '/p/class/list',
    element: <ClassPage />,
    meta: {
      action: 'read',
      resource: 'class'
    }
  },
  {
    path: '/p/class/add',
    element: <ClassEditPage />,
    meta: {
      action: 'add',
      resource: 'class'
    }
  },
  {
    path: '/p/class/:id/edit',
    element: <ClassEditPage />,
    meta: {
      action: 'update',
      resource: 'class'
    }
  },
  {
    path: '/p/class/view/:id',
    element: <ClassDetailPage />,
    meta: {
      action: 'detail',
      resource: 'class'
    }
  },
  {
    element: <Attendance />,
    path: '/p/attendance',
    meta: {
      appLayout: true,
      className: 'attendance-management',
      action: 'add',
      resource: 'stu'
    }
  },
  {
    element: <TechAttendance />,
    path: '/p/attendance-teachers',
    meta: {
      appLayout: true,
      className: 'attendance-management',
      action: 'add',
      resource: 'tech'
    }
  },
]

export default DashboardRoutes