/* eslint-disable no-unused-vars */
// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/@core/apiFace/index'

export const getData = createAsyncThunk('appAmc/getData', async (params) => {
  const response = await axios.get(`/amcs`, {
    params
  })
  return {
    params,
    data: response.data.data,
    pagination: response.data.pagination
  }
})

export const getAmc = createAsyncThunk(
  'appAmc/getAmc',
  async (id) => {
    const response = await axios.get(`/amcs/show/${id}`)
    return response.data.data
  }
)

export const addAmc = createAsyncThunk(
  'appAmc/addAmc',
  async (category, { dispatch, getState }) => {
    // eslint-disable-next-line no-use-before-define
    dispatch(apiCallStart())
    try {
      await axios.post(`amcs/store`, category)
      await dispatch(getData(getState().pAmc.params))
    } catch (e) {
      // eslint-disable-next-line no-use-before-define
      dispatch(apiCallError(e.response.data.message))
    } finally {
      // eslint-disable-next-line no-use-before-define
      dispatch(apiCallEnd())
    }
    return user
  }
)

export const editAmc = createAsyncThunk(
  'appAmc/editAmc',
  async ({ id, data }, { dispatch, getState }) => {
    await axios.post(`amcs/update/${id}`, data)
    await dispatch(getData(getState().pAmc.params))
    return id
  }
)

export const deleteAmc = createAsyncThunk(
  'appAmc/deleteAmc',
  async (id, { dispatch, getState }) => {
    await axios.get(`amcs/remove/${id}`)
    await dispatch(getData(getState().pAmc.params))
    return id
  }
)

export const resetFormSelected = createAsyncThunk(
  'appAmc/resetFormSelected')

export const appAmcSlice = createSlice({
  name: 'appAmc',
  initialState: {
    data: [],
    total: 1,
    params: {
      orderAsc: 1,
      orderBy: 'endDate'
    },
    selected: null,
    api: {
      apiCalls: 0,
      apiError: null
    },
    parentData: {}
  },
  reducers: {
    apiCallStart: (state) => {
      state.api.apiCalls++
    },
    apiCallEnd: (state) => {
      if (state.api.apiCalls > 0) {
        state.api.apiCalls--
      }
    },
    apiCallError: (state, action) => {
      state.api.apiError = action.payload || null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.pagination.count
      }).addCase(getAmc.fulfilled, (state, action) => {
        state.selected = action.payload
      }).addCase(resetFormSelected.fulfilled, (state, action) => {
        state.selected = null
      })
  }
})

export const { apiCallStart, apiCallEnd, apiCallError } = appAmcSlice.actions

export default appAmcSlice.reducer
