// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/@core/apiFace/index'

export const getTasks = createAsyncThunk('appTodo/getTasks', async params => {
  const response = await axios.get('/tasks', { params })
  return {
    params,
    data: response.data.data
  }
})

export const addTask = createAsyncThunk('appTodo/addTask', async ({ task, handler }, { dispatch, getState }) => {
  let response
  dispatch(apiCallStart())
  try {
    response = await axios.post('/tasks/store', { ...task })
    await dispatch(getTasks(getState().todo.params))
    handler()
  } catch (e) {
    dispatch(apiCallError(e.response.data.message))
  } finally {
    dispatch(apiCallEnd())
    setTimeout(() => dispatch(apiCallError(null)), 7000)
  }
  return response ? response.data : null
})

export const getTags = createAsyncThunk('appTodo/getTags', async params => {
  const response = await axios.get('/tags', { params })
  return {
    data: response.data.data
  }
})

export const addTags = createAsyncThunk('appTodo/addTags', async (task, { dispatch, getState }) => {
  const response = await axios.post('/tags/store', { ...task })
  await dispatch(getTags({
    orderBy: 'name',
    orderAsc: 1,
    limt: 9999
  }))
  return response.data
})

export const updateTask = createAsyncThunk('appTodo/updateTask', async ({ id, data, handler }, { dispatch, getState }) => {
  let response
  try {
    response = await axios.post(`/tasks/update/${id}`, { ...data })
    await dispatch(getTasks(getState().todo.params))
    handler()
  } catch (e) {
    dispatch(apiCallError(e.response.data.message))
  } finally {
    dispatch(apiCallEnd())
    setTimeout(() => dispatch(apiCallError(null)), 7000)
  }
  return response ? response.data : null
})

export const deleteTask = createAsyncThunk('appTodo/deleteTask', async (taskId, { dispatch, getState }) => {
  const response = await axios.get(`tasks/remove/${taskId}`)
  await dispatch(getTasks(getState().todo.params))
  return response.data
})

export const appTodoSlice = createSlice({
  name: 'appTodo',
  initialState: {
    tasks: [],
    tags: [],
    selectedTask: {},
    params: {
      filter: '',
      q: '',
      orderBy: '',
      tag: '',
      orderAsc: 0
    },
    api: {
      apiCalls: 0,
      apiError: null
    },
  },
  reducers: {
    reOrderTasks: (state, action) => {
      state.tasks = action.payload
    },
    selectTask: (state, action) => {
      state.selectedTask = action.payload
    },
    apiCallStart: (state) => {
      state.api.apiCalls++
    },
    apiCallEnd: (state) => {
      if (state.api.apiCalls > 0) {
        state.api.apiCalls--
      }
    },
    apiCallError: (state, action) => {
      state.api.apiError = action.payload || null
    }
  },
  extraReducers: builder => {
    builder.addCase(getTasks.fulfilled, (state, action) => {
      state.tasks = action.payload.data
      state.params = action.payload.params
    }).addCase(getTags.fulfilled, (state, action) => {
      state.tags = action.payload.data
    })
  }
})

export const { reOrderTasks, selectTask, apiCallStart, apiCallEnd, apiCallError } = appTodoSlice.actions

export default appTodoSlice.reducer
