// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/@core/apiFace/index'

export const getRoles = createAsyncThunk('appPermission/getRoles', async (params) => {
  const response = await axios.get(`roles`)
  return response.data.data
})

export const getData = createAsyncThunk('appPermission/getData', async (params) => {
  const response = await axios.get(`permissions`, {
    params
  })
  return {
    params,
    data: response.data.data,
    pagination: response.data.pagination
  }
})

export const getPermission = createAsyncThunk(
  'appPermission/getPermission',
  async (id) => {
    const response = await axios.get(`/permissions/show/${id}`)
    return response.data.data
  }
)

export const editPermission = createAsyncThunk(
  'appPermission/editPermission',
  async ({ id, data, navigator }, { dispatch, getState }) => {
    try {
      await axios.post(`permissions/store/${id}`, data)
      await dispatch(getData(getState().pPermission.params))
      navigator('/p/product/list')
    } catch (e) {
      dispatch(apiCallError(e.response.data.message))
    } finally {
      dispatch(apiCallEnd())
      setTimeout(() => dispatch(apiCallError(null)), 7000)
    }
    return id
  }
)

export const appPermissionSlice = createSlice({
  name: 'appPermission',
  initialState: {
    data: [],
    formattedData: [],
    roles: [],
    total: 1,
    params: {},
    selected: null,
    api: {
      apiCalls: 0,
      apiError: null
    },
    parentData: {}
  },
  reducers: {
    apiCallStart: (state) => {
      state.api.apiCalls++
    },
    apiCallEnd: (state) => {
      if (state.api.apiCalls > 0) {
        state.api.apiCalls--
      }
    },
    apiCallError: (state, action) => {
      state.api.apiError = action.payload || null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.formattedData = action.payload.data.formatted
        console.log(action.payload.data.formatted,'action.payload.data.formatted')
        state.params = action.payload.params
        state.total = action.payload.pagination.count
      }).addCase(getRoles.fulfilled, (state, action) => {
        state.roles = action.payload
      }).addCase(getPermission.fulfilled, (state, action) => {
        state.selected = action.payload
      })
  }
})

export const { apiCallStart, apiCallEnd, apiCallError } = appPermissionSlice.actions

export default appPermissionSlice.reducer
