import { API_ENDPOINT } from "@utils"

// ** Auth Endpoints
const URL = API_ENDPOINT()

export default {
  loginEndpoint: `${URL}/users/login`,
  registerEndpoint: `${URL}/users/register`,
  refreshEndpoint: `${URL}/users/refresh-token`,
  refreshMe: `${URL}/users/me`,
  logoutEndpoint: `${URL}/users/logout`,

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken'
}
