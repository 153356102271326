// ** React Imports
import { Link } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'

// ** Custom Components
import Avatar from '@components/avatar'

// ** Utils
import { isUserLoggedIn } from '@utils'

// ** Store & Actions
import { useDispatch } from 'react-redux'
import { handleLogout } from '@store/authentication'

// ** Third Party Components
import { User, Settings, Power } from 'react-feather'

// ** Reactstrap Imports
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap'

// ** Default Avatar Image
import defaultAvatar from '@src/assets/images/portrait/small/avatar-s-11.jpg'
import { MEDIA_IMAGE_SIZE } from '@utils'
import useJwt from '@src/auth/jwt/useJwt'
import { handleLogin } from '@store/authentication'
import { AbilityContext } from '@src/utility/context/Can'

const UserDropdown = () => {
  // ** Store Vars
  const dispatch = useDispatch()

  // ** State
  const [userData, setUserData] = useState(null)
  const ability = useContext(AbilityContext)

  //** ComponentDidMount
  useEffect(() => {
    if (isUserLoggedIn() !== null) {
      setUserData(JSON.parse(localStorage.getItem('userData')))
      setTimeout(() => {
        if (isUserLoggedIn() !== null) {
          useJwt.refreshMe().then(res => {
            const data = {
              ...res.data,
              accessToken: res.data.token,
              refreshToken: res.data.token
            }
            data.role = res.data.designation
            data.ability = res.data.permissions

            if (process.env.REACT_APP_ENV == 'dev') {
              ability.update([
                {
                  action: 'manage',
                  subject: 'all'
                }
              ])
            } else {
              ability.update([])
              ability.update(data.ability)
            }
            dispatch(handleLogin(data))
          })
        }

      }, 3000)
    }
  }, [])

  //** Vars
  const userAvatar = (userData && userData.profileImg ? MEDIA_IMAGE_SIZE(userData.profileImg, 's') : null) || defaultAvatar

  return (
    <UncontrolledDropdown tag='li' className='dropdown-user nav-item'>
      <DropdownToggle href='/' tag='a' className='nav-link dropdown-user-link' onClick={e => e.preventDefault()}>
        <div className='user-nav d-sm-flex d-none'>
          <span className='user-name fw-bold'>{(userData && userData['name']) || 'John Doe'}</span>
          <span className='user-status'>{(userData && userData.designation) || ''}</span>
        </div>
        <Avatar img={userAvatar} imgHeight='40' imgWidth='40' status='online' />
      </DropdownToggle>
      <DropdownMenu end>
        <DropdownItem tag={Link} to='/p/users/profile'>
          <User size={14} className='me-75' />
          <span className='align-middle'>Profile</span>
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem tag={Link} to='/login' onClick={() => dispatch(handleLogout())}>
          <Power size={14} className='me-75' />
          <span className='align-middle'>Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default UserDropdown
