// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/@core/apiFace/index'

export const getParentCategory = createAsyncThunk(
  'appCategory/getParentCategory',
  async () => {
    const response = await axios.get(
      `categories?orderAsc=1&orderBy=name&q=&page=1&limit=99999`
    )
    return response.data.data.rows
  }
)

export const getData = createAsyncThunk('appCategory/getData', async (params) => {
  const response = await axios.get(`categories`, {
    params
  })
  return {
    params,
    data: response.data.data,
    pagination: response.data.pagination
  }
})

export const getCategory = createAsyncThunk(
  'appCategory/getCategory',
  async (id) => {
    const response = await axios.get(`categories/show/${id}`)
    return response.data.data
  }
)

export const addCategory = createAsyncThunk(
  'appCategory/addCategory',
  async (category, { dispatch, getState }) => {
    // eslint-disable-next-line no-use-before-define
    dispatch(apiCallStart())
    try {
      await axios.post(`categories/store`, category)
      await dispatch(getData(getState().pCategory.params))
    } catch (e) {
      // eslint-disable-next-line no-use-before-define
      dispatch(apiCallError(e.response.data.message))
    } finally {
      // eslint-disable-next-line no-use-before-define
      dispatch(apiCallEnd())
    }
    return user
  }
)

export const deleteCategory = createAsyncThunk(
  'appCategory/deleteCategory',
  async (id, { dispatch, getState }) => {
    await axios.delete('/apps/users/delete', { id })
    await dispatch(getData(getState().pCategory.params))
    return id
  }
)

export const appCategorySlice = createSlice({
  name: 'appCategory',
  initialState: {
    data: [],
    total: 1,
    params: {},
    selected: null,
    api: {
      apiCalls: 0,
      apiError: null
    },
    parentData: {
      list: null,
      parentId: [],
      type: [
        { value: 'deafult', label: 'Deafult' },
        { value: 'menu', label: 'Menu' },
        { value: 'link', label: 'Link' }
      ]
    }
  },
  reducers: {
    apiCallStart: (state) => {
      state.api.apiCalls++
    },
    apiCallEnd: (state) => {
      if (state.api.apiCalls > 0) {
        state.api.apiCalls--
      }
    },
    apiCallError: (state, action) => {
      state.api.apiError = action.payload || null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getParentCategory.fulfilled, (state, action) => {
        state.parentData['parentId'] = toSelectOprtions(
          action.payload,
          '_id',
          'name'
        )
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data.rows
        state.parentData.list = action.payload.data.extraData.parent
        state.params = action.payload.params
        state.total = action.payload.pagination.count
      })
      .addCase(getCategory.fulfilled, (state, action) => {
        state.selected = action.payload
      })
  }
})

export const { apiCallStart, apiCallEnd, apiCallError } = appCategorySlice.actions

export default appCategorySlice.reducer
