import { lazy } from 'react'

const DashboardHome = lazy(() => import('../../views/store/dashboard/home'))

const DashboardRoutes = [
  {
    path: '/dashboard/home',
    action: 'read',
    resource: 'dashboard',
    element: <DashboardHome />,
    meta: {
      action: 'read',
      resource: 'dashboard',
      subject: 'dashboard',
    }
  }
]

export default DashboardRoutes